
import Vue from 'vue';
import {Prop} from 'vue-property-decorator';
import {Donut} from '@/models/donut';
import Component from 'vue-class-component';

@Component({
    name: 'chart-detail-tile'
})
export default class ChartDetailTile extends Vue {
    @Prop({required: true})
    private data!: Donut;
}
