
import Vue from 'vue';
import Component from 'vue-class-component';
import NotificationTileHeader from '@/components/notification/components/notification-tile-header.vue';
import NotificationTileHeaderPlant from '@/components/notification/components/notification-tile-header-plant.vue';
import NotificationTileBody from '@/components/notification/components/notification-tile-body.vue';
import {Emit, Prop, Watch} from 'vue-property-decorator';
import {SignalrNotification} from '@/models/signalr-notification/signalr-notification';
import NotificationTileFooter from '@/components/notification/components/notification-tile-footer.vue';
import {differenceTwoDates} from '@/utils/utils';

@Component({
    name: 'notification-tile',
    components: {NotificationTileFooter, NotificationTileBody, NotificationTileHeaderPlant, NotificationTileHeader},
})
export default class NotificationTile extends Vue {
    private locale: string = this.$i18n.locale;

    @Prop({required: true})
    private notification!: SignalrNotification;

    @Emit('on-click-notification')
    private onClickNotification(notification: SignalrNotification): void {
        return;
    }

    private get difference(): string {
        return differenceTwoDates(this.notification.timestamp, new Date(), {disableMinutes: true, disableHours: true})
    }

    @Watch('$i18n.locale')
    private onLocaleChanged(): void {
        this.locale = this.$i18n.locale;
    }

    private get title(): string {
        if (this.notification.translations && this.notification.translations[this.locale]) {
            return this.notification.translations[this.locale].title
        }
        return this.notification.title;
    }

    private get description(): string {
        if (this.notification.translations && this.notification.translations[this.locale]) {
            return this.notification.translations[this.locale].description
        }
        return this.notification.description;
    }

}
