import {PlantType} from '@/models/enums/plant-type';

export interface PlantDTO {
    unitId: string;
    plantName: string;
    unitName: string;
    country: string;
    fleetName: string;
    capacity: number;
    totalCapacity: number;
    endDate: Date;
    startDate: Date;
    type: PlantType;
    description: string;
}

export interface Plant {
    eventId: string;
    eventIdentifier: string;
    eventTitle: string;
    eventDescription: string;
    availCapacityUnit: number;
    unAvailCapacityUnit: number;
    netCapacityTec: number;
    netMinCapacityTec: number;
    createdBy: string;
    updatedBy: string;
    eventMachineSid: string;
    unitId: string;
    unitName: string;
    fleetName: string;
    eventPlantSid: string;
    plantName: string;
    enumAreaSsid: string;
    companyId: string;
    country: string;
    enumEventTypeSid: string;
    enumEventStatusSid: string;
    enumEventReasonEexSid: string;
    enumEventReasonSid: string;
    enumEventTypeName: string;
    enumEventNatureName: string;
    fleetSid: number;
    minCapacityEvent: number;
    confirmEnergyThreshold: number;
    plannedUnplannedBoundry: number;
    isExternalInfluence: boolean;
    isMustRun: boolean;
    isPlanned: boolean;
    isTestRun: boolean;
    utcStartTime: Date;
    utcEndTime: Date;
    expectedUtcEndTime: Date;
    validFrom: Date;
    validTo: Date;
    createdAt: Date;
    updatedAt: Date;
}

export const plantDtoConverter = (value: Plant): PlantDTO => {
    return {
        plantName: value.plantName,
        unitName: value.unitName,
        country: value.country,
        endDate: value.utcEndTime,
        startDate: value.utcStartTime,
        capacity: value.availCapacityUnit,
        totalCapacity: value.netCapacityTec,
        fleetName: value.fleetName,
        type: value.isPlanned ? PlantType.PLANNED : PlantType.UNPLANNED,
        unitId: value.unitId,
        description: value.eventDescription,
    }
}


