
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {PlantType} from '@/models/enums/plant-type';
import {PlantDetail} from '@/models/plant-detail';

@Component({
    name: 'plant-detail-tile'
})
export default class PlantDetailTile extends Vue {

    @Prop({required: true})
    private plant!: PlantDetail

    @Prop({default: '#fff'})
    private colorCard!: string;

    private plannedOrUnplanned(): boolean {
        return this.plant.status === PlantType.PLANNED;
    }

    private generateStyle(): object {
        return {
            'background-color': this.colorCard,
        }
    }
}
