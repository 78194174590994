
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component({
    name: 'notification-tile-body'
})
export default class NotificationTileBody extends Vue {
    @Prop({default: ''})
    private description!: string;

    private mounted(): void {
        this.description = this.description.replace('\n', '');
    }
}
