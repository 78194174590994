
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component({
    name: 'pebble-tile-card',
})
export default class PebbleTileCard extends Vue {
    @Prop({required: false})
    private borderColor?: string;

    private get generateStyle(): object {
        if (!this.borderColor) return {};
        return {
            'border-left': '6px solid',
            'border-left-color': this.borderColor,
        };
    }
}
