

import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component({
    name: 'search-field',
    watch: {
        searchText(value: string): void {
            this.$emit('text', value);
        }
    }
})
export default class SearchField extends Vue {
    @Prop({default: ''}) private placeholder?: string;
    private searchText = '';

    get icon(): string {
        return this.searchText === '' ? 'search' : 'close';
    }
}
