
import Vue from 'vue';
import Component from 'vue-class-component';
import {Emit, Prop} from 'vue-property-decorator';

@Component({
    name: 'pebble-popover',
})
export default class PebblePopover extends Vue {
    @Prop({default: false})
    private isVisible!: boolean;

    @Emit('on-click-popover')
    private onClickPopover(): void {
        return;
    }

}
