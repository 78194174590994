
import Vue from 'vue';
import PlantDetailHeader from '@/components/page-header/headers/plant-detail-header.vue';
import HomeHeader from '@/components/page-header/headers/home-header.vue';
import Component from 'vue-class-component';
import {Watch} from 'vue-property-decorator';

@Component({
    name: 'page-header',
    components: {
        HomeHeader,
        PlantDetailHeader
    },
})
export default class PageHeader extends Vue {
    private component = 'home-header';

    private mounted(): void {
        this.change(this.$route.name ?? '');
    }

    @Watch('$route.name')
    private onChangeRoute(value: string): void {
        this.change(value);
    }

    private change(value: string): void {
        switch (value) {
        case 'home-page':
            this.component = 'home-header';
            break;
        case 'plant-details-page':
            this.component = 'plant-detail-header';
            break;
        }
    }
}
