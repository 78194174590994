
import Vue from 'vue';
import Component from 'vue-class-component';
import {Emit} from 'vue-property-decorator';

@Component({
    name: 'notification-tile-footer',
})
export default class NotificationTileFooter extends Vue {
    @Emit('on-click-button')
    private onClickButton(): void {
        return;
    }

}
