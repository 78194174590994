
import Vue from 'vue';
import Component from 'vue-class-component';
import {Watch} from 'vue-property-decorator';

@Component({
    name: 'plant-detail-header'
})
export default class PlantDetailHeader extends Vue {
    private links: object[] = [];
    private titleHeader = '';

    private mounted(): void {
        this.generateLinks();
    }

    private onActiveRouteChanged(): void {
        this.$router.replace({name: 'home-page'});
    }

    @Watch('$i18n.locale')
    private onLocaleChanged(): void {
        this.generateLinks();
    }

    private generateLinks(): void {
        const namePlant = this.$route.query.namePlant;
        const countryPlant = `${this.$route.query.countryPlant}`;
        if (namePlant && countryPlant) {
            this.titleHeader = `${namePlant} (${this.$t(`country.${countryPlant.replace(/ /g, '').toLowerCase()}`).toString()})`;
            this.links = [
                {label: this.$t('backPowerPlantsTitle').toString(), href: '#'},
                {label: this.titleHeader, href: '##'}
            ];
        }
    }

}
