
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop, Watch} from 'vue-property-decorator';
import {PlantDTO} from '@/models/plant';
import {Donut} from '@/models/donut';
import CustomDonut from '@/components/chart/custom-donut.vue';
import {DonutType} from '@/models/enums/donut-type';
import ChartDetailList from '@/components/chart-detail-list/chart-detail-list.vue';
import {DonutValueType} from '@/models/enums/donut-value-type';
import moment from 'moment';
import {PlantType} from '@/models/enums/plant-type';

@Component({
    name: 'chart-card',
    components: {ChartDetailList, CustomDonut},
})
export default class ChartCard extends Vue {
    @Prop({required: true})
    private plants!: PlantDTO[];

    private portfolio: Donut[] = [
        {
            label: this.$t('donutDetail.OneUnavailable').toString(),
            color: 'red',
            count: 0,
            active: false,
            link: {
                label: this.$t('donutSelectButton').toString()
            },
            donutType: DonutType.PORTFOLIO,
            donutValueType: DonutValueType.UNAVAILABLE,
        },
        {
            label: this.$t('donutDetail.OneReducedAvailable').toString(),
            color: 'orange',
            count: 0,
            active: false,
            link: {
                label: this.$t('donutSelectButton').toString()
            },
            donutType: DonutType.PORTFOLIO,
            donutValueType: DonutValueType.REDUCED_AVAILABLE,
        },
        {
            label: this.$t('donutDetail.OneAvailable').toString(),
            color: 'green',
            count: 0,
            active: false,
            link: {
                label: this.$t('donutSelectButton').toString()
            },
            donutType: DonutType.PORTFOLIO,
            donutValueType: DonutValueType.AVAILABLE,
        }
    ];

    private outage: Donut[] = [
        {
            label: this.$t('donutDetail.OneUnplanned').toString(),
            color: 'red',
            count: 0,
            active: false,
            link: {
                label: this.$t('donutSelectButton').toString()
            },
            donutType: DonutType.OUTAGE,
            donutValueType: DonutValueType.UNPLANNED,
        },
        {
            label: this.$t('donutDetail.OnePlanned').toString(),
            color: 'green',
            count: 0,
            active: false,
            link: {
                label: this.$t('donutSelectButton').toString()
            },
            donutType: DonutType.OUTAGE,
            donutValueType: DonutValueType.PLANNED,
        }
    ];

    mounted(): void {
        this.initData();
    }

    @Watch('plants')
    @Watch('$i18n.locale')
    private initData(): void {
        this.portfolio.map(value => value.count = 0);
        this.outage.map(value => value.count = 0);

        const now = moment(new Date());
        this.plants.map((value: PlantDTO) => {
            if (now.isBetween(moment(value.startDate), moment(value.endDate))) {
                this.generateOutage(value);
                if (value.capacity === 0) {
                    this.portfolio[0].count = this.portfolio[0].count + 1;
                    if (this.portfolio[0].count >= 1) this.portfolio[0].label = this.$t('donutDetail.Unavailable').toString();
                } else {
                    this.portfolio[1].count = this.portfolio[1].count + 1;
                    if (this.portfolio[1].count >= 1) this.portfolio[1].label = this.$t('donutDetail.ReducedAvailable').toString();
                }
            } else {
                this.portfolio[2].count = this.portfolio[2].count + 1;
                if (this.portfolio[2].count >= 1) this.portfolio[2].label = this.$t('donutDetail.Available').toString();
            }
        });
    }

    private generateOutage(value: PlantDTO): void {
        if (value.type === PlantType.PLANNED) {
            this.outage[1].count = this.outage[1].count + 1;
            if (this.outage[1].count > 1) this.outage[1].label = this.$t('donutDetail.Planned').toString();
        } else {
            this.outage[0].count = this.outage[0].count + 1;
            if (this.outage[0].count > 1) this.outage[0].label = this.$t('donutDetail.Unplanned').toString();
        }
    }

    private generateTitlePortfolio(): string {
        const data = this.plants.reduce(
            (accumulator: number, value: PlantDTO) => accumulator += value.capacity ?? 0, 0
        );
        return (data - Math.floor(data)) !== 0 ? data.toFixed(1) : data.toString();

    }

    private generateTitleOutage(): string {
        let data = 0;
        const now = moment(new Date());
        this.plants.map(value => {
            if (now.isBetween(moment(value.startDate), moment(value.endDate))) {
                data += (value.totalCapacity ?? 0) - (value.capacity ?? 0);
            }
        });
        return (data - Math.floor(data)) !== 0 ? data.toFixed(1) : data.toString();
    }

    private onSelectPortfolio(value: Donut): void {
        this.$store.commit('setPortfolioDonut', value);
    }

    private onSelectOutage(value: Donut): void {
        this.$store.commit('setOutageDonut', value);
    }

    private clearPortfolioFilter(): void {
        this.portfolio.map(value => {
            value.active = false;
        });
        this.onSelectPortfolio(this.portfolio[-1]);
        this.initData();
    }

    private clearOutageFilter(): void {
        this.outage.map(value => {
            value.active = false;
        });
        this.onSelectOutage(this.outage[-1]);
        this.initData();
    }
}
