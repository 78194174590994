import Component from 'vue-class-component';
import Vue from 'vue';
import {NotificationService} from '@/services/notification-service';
import {Watch} from 'vue-property-decorator';
import {SignalrNotification} from '@/models/signalr-notification/signalr-notification';

@Component
export default class ComponentNotification extends Vue {
    private notificationService: NotificationService = new NotificationService();

    public async getNotifications(): Promise<void> {
        try {
            this.$store.commit('loading');
            const {items} = (await this.notificationService.getNotifications()).result;
            const notifications = items.filter((value: SignalrNotification) => {
                return value.useCaseId === +process.env.VUE_APP_USE_CASE_ID;
            })
            this.$store.commit('setNotifications', notifications);
            this.$forceUpdate();
        } catch (e) {
            console.error(e);
        } finally {
            this.$store.commit('loading');
        }
    }

    @Watch('$store.state.loadNotification')
    private async reloadNotification(value: boolean): Promise<void> {
        if (value) {
            console.log('LOAD NEW NOTIFICATION');
            await this.getNotifications();
            this.$store.commit('setLoadNotification', false);
        }
    }

    public async markAsReadSingleNotification(id: number): Promise<void> {
        try {
            this.$store.commit('loading');
            await this.notificationService.markAsReadNotification(id);
            await this.getNotifications();
        } catch (e) {
            console.error(e);
        } finally {
            this.$store.commit('loading');
        }
    }

    public async readAllNotification(): Promise<void>{
        try {
            this.$store.commit('loading');
            await this.notificationService.readAllNotifications();
            await this.getNotifications();
        } catch (e) {
            console.error(e);
        } finally {
            this.$store.commit('loading');
        }
    }
}
