import APIConnection from './api-connection';

export default class BaseService {
    protected api: APIConnection = new APIConnection(process.env.VUE_APP_REPOSITORY_API_BASE_URL);
    protected endpoint = '';

    constructor(endpoint: string, baseURL?: string) {
        this.endpoint = endpoint;
        if (baseURL) {
            this.api = new APIConnection(baseURL);
        }
    }
}
