
import Vue from 'vue';
import Component from 'vue-class-component';
import SearchField from '@/components/search-field/search-field.vue';
import PebbleFilter from '@/components/pebble-components/pebble-filter/pebble-filter.vue';
import {Filter} from '@/models/pebble/pebble-filter/filter';
import {CustomObject} from '@/models/custom-object';
import {Watch} from 'vue-property-decorator';

@Component({
    name: 'home-header',
    components: {PebbleFilter, SearchField},
})
export default class HomeHeader extends Vue {
    private filters: Filter[] = [];

    @Watch('$store.state.countryFilter')
    @Watch('$store.state.fleetFilter')
    @Watch('$i18n.locale')
    private generateFilters(): void {
        this.filters = [];
        if (this.$store.getters.getCountryFilter.length > 0) {
            this.filters.push(this.generateCountryFilter());
        }
        if (this.$store.getters.getFleetFilter.length > 0) {
            this.filters.push(this.generateFleetFilter());
        }
        this.$forceUpdate();
    }

    private onFilterChange(data: CustomObject): void {
        if (data['country']) {
            this.$store.commit('setSelectedCountries', data['country']);
        }
        if (data['fleet']) {
            this.$store.commit('setSelectedFleet', data['fleet']);
        }
    }

    private generateCountryFilter(): Filter {
        return {
            name: 'country',
            displayName: this.$t('countryFilter').toString(),
            type: 'multiselect',
            isExpandable: true,
            isExpanded: true,
            appliedValues: {
                options: this.$store.getters.getSelectedCountries,
            },
            selectedValues: {
                options: this.$store.getters.getSelectedCountries,
            },
            config: {
                hasSearchInput: true,
                searchInputPlaceholder: this.$t('searchCountryFilter').toString(),
                options: this.$store.getters.getCountryFilter,
            }
        };
    }

    private generateFleetFilter(): Filter {
        return {
            name: 'fleet',
            displayName: this.$t('fleetFilter').toString(),
            type: 'multiselect',
            isExpandable: true,
            isExpanded: true,
            appliedValues: {
                options: this.$store.getters.getSelectedFleet,
            },
            selectedValues: {
                options: this.$store.getters.getSelectedFleet,
            },
            config: {
                hasSearchInput: true,
                searchInputPlaceholder: this.$t('searchFleetFilter').toString(),
                options: this.$store.getters.getFleetFilter,
            }
        };
    }

}
