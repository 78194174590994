import Vue from 'vue';
import Vuex from 'vuex';
import {PlantDTO} from '@/models/plant';
import {Donut} from '@/models/donut';
import {FilterValue} from '@/models/pebble/pebble-filter/filter-value';
import {SignalrNotification} from '@/models/signalr-notification/signalr-notification';

Vue.use(Vuex);

const applicationState: {
    isLoading: boolean;
    countryFilter: FilterValue[];
    fleetFilter: FilterValue[];
    searchedText: string;
    selectedCountries: string[];
    selectedFleets: string[];
    portfolioDonut?: Donut;
    outageDonut?: Donut;
    plants: PlantDTO[];
    filteredPlants: PlantDTO[];
    notifications: SignalrNotification[];
    loadNotification: boolean;
} = {
    isLoading: false,
    countryFilter: [],
    fleetFilter: [],
    searchedText: '',
    selectedCountries: [],
    selectedFleets: [],
    portfolioDonut: undefined,
    outageDonut: undefined,
    plants: [],
    filteredPlants: [],
    notifications: [],
    loadNotification: false,
};

export default new Vuex.Store({
    state: applicationState,
    mutations: {
        loading(state): void {
            state.isLoading = !state.isLoading;
        },
        setCountryFilter(state, data): void {
            state.countryFilter = data;
        },
        setFleetFilter(state, data): void {
            state.fleetFilter = data;
        },
        setSearchedText(state, data): void {
            state.searchedText = data;
        },
        setSelectedCountries(state, data): void {
            state.selectedCountries = data;
        },
        setSelectedFleet(state, data): void {
            state.selectedFleets = data;
        },
        setPortfolioDonut(state, data): void {
            state.portfolioDonut = data;
        },
        setOutageDonut(state, data): void {
            state.outageDonut = data;
        },
        setPlants(state, data): void {
            state.plants = data;
        },
        setFilteredPlants(state, data): void {
            state.filteredPlants = data;
        },
        setNotifications(state, data): void {
            state.notifications = data;
        },
        setLoadNotification(state, data): void {
            state.loadNotification = data;
        }
    },
    actions: {},
    modules: {},
    getters: {
        isLoading(state): boolean {
            return state.isLoading;
        },
        getCountryFilter(state): FilterValue[] {
            return state.countryFilter;
        },
        getFleetFilter(state): FilterValue[] {
            return state.fleetFilter;
        },
        getSearchedText(state): string {
            return state.searchedText;
        },
        getSelectedCountries(state): string[] {
            return state.selectedCountries;
        },
        getSelectedFleet(state): string[] {
            return state.selectedFleets;
        },
        getPlants(state): PlantDTO[] {
            return state.plants;
        },
        getFilteredPlants(state): PlantDTO[] {
            return state.filteredPlants;
        },
        getPortfolioDonut(state): Donut | undefined {
            return state.portfolioDonut;
        },
        getOutageDonut(state): Donut | undefined {
            return state.outageDonut;
        },
        getNotifications(state): SignalrNotification[] {
            return state.notifications;
        },
        getLoadNotification(state): boolean {
            return state.loadNotification;
        }
    },
});
