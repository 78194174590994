import BaseService from '@/utils/base-service';
import {ApiResponse, ApiResponseList} from '@/models';
import {Plant, PlantDTO, plantDtoConverter} from '@/models/plant';

export class PlantDetailService extends BaseService {
    constructor() {
        super('power-plant-event', process.env.VUE_APP_API_BASE_URL + '/api/v1');
    }

    public async getPlantDetail(unitId: string): Promise<ApiResponse<ApiResponseList<PlantDTO>>> {
        const data: ApiResponse<ApiResponseList<Plant>> = (await this.api.get(`${this.endpoint}/${unitId}/details`)).data;
        const temp = data.result.items.map((value: Plant): PlantDTO => plantDtoConverter(value));
        return {statusCode: data.statusCode, message: data.message, result: {items: temp}}
    }
}
