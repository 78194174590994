
import Vue from 'vue';
import Component from 'vue-class-component';
import PebbleDrawer from '@/components/pebble-components/pebble-drawer/pebble-drawer.vue';
import UserCard from '@/components/user-card/user-card.vue';
import PebbleApps from '@/components/pebble-components/pebble-apps/pebble-apps.vue';
import {Emit, Prop, Watch} from 'vue-property-decorator';
import {UniperUser} from '@/models/uniper-user';
import {PebbleAvatarInformation} from '@/models/pebble/pebble-avatar-information';
import PebbleApp from '@/models/pebble/pebble-app';
import {EventBus} from '@/utils';
import {Application} from '@/models';
import {AppService} from '@/services';
import NotificationWrapper from '@/components/notification/notification-wrapper.vue';
import PebbleAppsConfiguration from '@/models/pebble/pebble-apps-configuration';

@Component({
    name: 'drawer-wrapper',
    components: {NotificationWrapper, PebbleApps, UserCard, PebbleDrawer}
})
export default class DrawerWrapper extends Vue {
    /* VARIABLES */
    private appService: AppService = new AppService();
    private configuration: PebbleAppsConfiguration = {
        sections: []
    }

    /* LATE VARIABLES */
    private apps: PebbleApp[] = [];

    @Prop({required: true})
    private userLogged!: UniperUser;

    @Prop({required: true})
    private avatarInformation!: PebbleAvatarInformation;

    @Prop()
    private appsDrawer = false;

    @Prop()
    private notificationDrawer = false;

    @Emit('on-click-close-drawer')
    private onClickCloseDrawer(): void {
        return;
    }

    private mounted(): void {
        this.init();
    }

    private async init(): Promise<void> {
        this.generateSections();
        await this.loadApps();
    }

    private onClickApp(app: PebbleApp): void {
        if (app.url) {
            window.open(app.url);
            this.onClickCloseDrawer();
        }
    }

    private async loadApps(): Promise<void> {
        this.$store.commit('loading');
        try {
            const apps = (await this.appService.getApps()).result.items;
            this.apps = this.processApps(apps);
        } catch (err) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingApplications');
            throw err;
        } finally {
            this.$store.commit('loading');
        }
    }

    private processApps(apps: Application[]): PebbleApp[] {
        return apps.map((app: Application): PebbleApp => ({
            id: app.useCaseId,
            title: app.name,
            logo: app.logo,
            isEnabled: app.hasAccess,
            type: app.hasAccess ? 'APP_WITH_ACCESS' : 'APP_WITHOUT_ACCESS',
            url: app.url ? app.url : undefined,
        })).sort((a: PebbleApp, b: PebbleApp) => {
            const nameA = a?.title?.toLowerCase();
            const nameB = b?.title?.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    private generateSections(): void {
        this.configuration = {
            sections: [
                {
                    name: 'APP_WITH_ACCESS',
                    label: this.$i18n.t('appsWithAccess').toString(),
                },
                {
                    name: 'APP_WITHOUT_ACCESS',
                    label: this.$i18n.t('appsWithoutAccess').toString(),
                }
            ]
        }
    }

    @Watch('$i18n.locale')
    private onLocaleChanged(): void {
        this.generateSections();
    }

}
