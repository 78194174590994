
import Vue from 'vue';
import Component from 'vue-class-component';
import {Emit} from 'vue-property-decorator';

@Component({
    name: 'pebble-header-menu',
})
export default class PebbleHeaderMenu extends Vue {
    @Emit('on-click-drawer-menu')
    private onClickDrawerMenu(): void {
        return;
    }
}
