import { render, staticRenderFns } from "./plant-detail-header.vue?vue&type=template&id=05ff260f&scoped=true&"
import script from "./plant-detail-header.vue?vue&type=script&lang=ts&"
export * from "./plant-detail-header.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05ff260f",
  null
  
)

export default component.exports