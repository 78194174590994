
import Vue from 'vue';
import Component from 'vue-class-component';
import PebbleDot from '@/components/pebble-components/pebble-dot/pebble-dot.vue';
import {Prop} from 'vue-property-decorator';

@Component({
    name: 'notification-tile-header-plant',
    components: {PebbleDot}
})
export default class NotificationTileHeaderPlant extends Vue {
    @Prop({default: ''})
    private title!: string;

    @Prop({default: false})
    private isRead!: boolean;
}
