
import Vue from 'vue';
import {Component, Watch} from 'vue-property-decorator';
import SearchField from '@/components/search-field/search-field.vue';
import {EventBus} from '@/utils';
import PlantsDetailList from '@/components/plants-list/plants-detail-list.vue';
import {PlantType} from '@/models/enums/plant-type';
import {PlantDetailService} from '@/services/plant-detail-service';
import moment from 'moment';
import PageHeader from '@/components/page-header/page-header.vue';
import PebbleTable from '@/components/pebble-components/pebble-table/pebble-table.vue';
import {PlantDetail} from '@/models/plant-detail';
import {clone} from '@/utils/utils';
import {PebbleStatus} from '@/models/pebble/enums/pebble-status';
import PebbleTableV2 from '@/components/pebble-components/pebble-table/pebble-table-v2.vue';
import {PlantDTO} from '@/models/plant';

@Component({
    name: 'plant-details-page',
    components: {PebbleTableV2, PebbleTable, PageHeader, PlantsDetailList, SearchField},
})
export default class PlantDetailsPage extends Vue {
    private data: PlantDetail[] = [];
    private plantsDetailsService: PlantDetailService = new PlantDetailService();
    private searchText = '';
    private dataStatus: PebbleStatus = PebbleStatus.LOADING;

    private get heading(): object {
        return {
            unavailabilityEvents: this.$t('tableHead.unavailabiltyEvent').toString(),
            status: this.$t('tableHead.status').toString(),
            description: this.$t('tableHead.description').toString(),
        };
    }

    private async mounted(): Promise<void> {
        await this.init();
    }

    @Watch('$i18n.locale')
    private async init(): Promise<void> {
        this.data.length = 0;
        this.data = await this.loadPlantDetails();
    }

    private async loadPlantDetails(): Promise<PlantDetail[]> {
        this.dataStatus = PebbleStatus.LOADING;
        let plantDetails: PlantDetail[] = [];
        try {
            const unitId = this.$route.params.id;
            if (unitId) {
                const data = (await this.plantsDetailsService.getPlantDetail(unitId)).result.items;
                plantDetails = data.map((value: PlantDTO): PlantDetail => {
                    return {
                        unavailabilityEvents: `${this.formatDate(value.startDate)} - ${this.formatDate(value.endDate)}`,
                        status: value.type,
                        description: value.description
                    }
                })
                this.dataStatus = PebbleStatus.LOADED
                return plantDetails;
            }
        } catch (err) {
            this.dataStatus = PebbleStatus.ERROR;
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingApplications');
            throw err;
        }
        return plantDetails;
    }

    private onSearchText(text: string): void {
        this.searchText = text;
    }

    private filterList(): PlantDetail[] {
        const self = this;
        let tempList: PlantDetail[] = clone(this.data);
        if (this.searchText !== '') {
            tempList = tempList.filter(function (plant: PlantDetail) {
                const temp: boolean[] = [];
                Object.keys(plant).forEach(value => {
                    temp.push((plant as any)[value].toLowerCase().startsWith(self.searchText.toLowerCase()));
                })
                return temp.includes(true);
            });
        }
        return tempList;
    }

    private plannedOrUnplanned(plant: PlantType): boolean {
        return plant === PlantType.PLANNED;
    }

    private formatDate(date?: Date): string {
        return moment(date).utc().format('DD MMM YYYY(HH:mm)');
    }

}
