
import Vue, {PropType} from 'vue';
import Component from 'vue-class-component';
import PebbleTableCaption from '@/components/pebble-components/pebble-table/components/pebble-table-caption.vue';
import PebbleTableFooter from '@/components/pebble-components/pebble-table/components/pebble-table-footer.vue';
import PebbleTableHeader from '@/components/pebble-components/pebble-table/components/pebble-table-header.vue';
import PebbleTableBody from '@/components/pebble-components/pebble-table/components/pebble-table-body.vue';
import {Prop, PropSync, Watch} from 'vue-property-decorator';
import {PebbleTableValue} from '@/models/pebble/pebble-table/pebble-table-value';
import {CustomObject} from '@/models/custom-object';
import {PlantDetail} from '@/models/plant-detail';
import {clone} from '@/utils/utils';
import {PebbleHeaderSort} from '@/models/pebble/pebble-header-sort';
import {PebbleHeaderSortStatus} from '@/models/pebble/enums/pebble-header-sort-status';

@Component({
    name: 'pebble-table-v2',
    components: {PebbleTableBody, PebbleTableHeader, PebbleTableFooter, PebbleTableCaption},
})
export default class PebbleTableV2 extends Vue {
    @PropSync('data', {
        type: Object as PropType<PebbleTableValue>, required: false, default: (): PebbleTableValue => {
            return {
                data: [],
                columns: [],
                options: {
                    perPage: 2,
                    perPageValues: [2, 4, 6]
                }
            }
        }
    })
    private readonly dataSync!: PebbleTableValue;

    @Prop({required: false})
    private heading?: CustomObject;

    @Prop({default: false, type: Boolean})
    private enableSearch!: boolean;

    @Prop({default: false, type: Boolean})
    private enableLimit!: boolean;

    private searchValue = '';
    private selectedPage = 1;
    private limit = 2;
    private sortable: PebbleHeaderSort[] = [];

    @Watch('dataSync')
    private onDataChanged(): void {
        this.limit = this.dataSync.options.perPage;
    }

    private get rows(): object[] {
        let tempList: object[] = clone(this.dataSync.data);
        const self = this;
        if (this.searchValue !== '' && this.enableSearch) {
            tempList = tempList.filter(function (plant: PlantDetail) {
                const temp: boolean[] = [];
                Object.keys(plant).forEach(item => {
                    temp.push((plant as any)[item].toLowerCase().startsWith(self.searchValue.toLowerCase()));
                })
                return temp.includes(true);
            });
            this.selectedPage = 1;
        }
        if (this.dataSync.options.sortable && this.dataSync.options.sortable?.length > 0) {
            this.dataSync.options.sortable.forEach((key: string) => {
                tempList = this.sortByKey(tempList, key)
            })
        }
        return tempList;
    }

    private onSearch(value: string): void {
        this.searchValue = value;
    }

    private onLimitChange(value: string): void {
        this.limit = +value;
    }

    private onSelectedPage(value: number): void {
        this.selectedPage = value;
    }

    private onCellSortChanged(value: PebbleHeaderSort[]): void {
        this.sortable = value;
    }

    private sortByKey(array: object[], key: string): object[] {
        const self = this;
        return array.sort(function (a: object, b: object) {
            if (self.sortable.length === 0) return -1;
            let status: PebbleHeaderSortStatus = PebbleHeaderSortStatus.NOTHING;
            self.sortable.forEach((value: PebbleHeaderSort) => {
                if (value.columnKey === key) {
                    status = value.sortStatus;
                }
            })
            if (status === PebbleHeaderSortStatus.NOTHING) return 0;
            const x = (a as CustomObject)[key];
            const y = (b as CustomObject)[key];
            if (status === PebbleHeaderSortStatus.ASCENDING) {
                return (x > y) ? -1 : 1;
            } else {
                return (x < y) ? -1 : 1;
            }
        });
    }

}
