
import Vue from 'vue';
import Component from 'vue-class-component';
import SearchField from '@/components/search-field/search-field.vue';
import {Emit, Prop, PropSync} from 'vue-property-decorator';
import {PlantDTO} from '@/models/plant';
import PlantTile from '@/components/plants-list/plant-tile.vue';
import {PebbleStatus} from '@/models/pebble/enums/pebble-status';
import PebbleCard from '@/components/pebble-components/pebble-card/pebble-card.vue';
import PebbleLoader from '@/components/pebble-components/pebble-loader/pebble-loader.vue';

@Component({
    name: 'plants-component',
    components: {PebbleLoader, PebbleCard, PlantTile, SearchField}
})
export default class PlantsComponent extends Vue {
    @PropSync('status', {type: Number, default: () => PebbleStatus.LOADING})
    private readonly statusSync!: PebbleStatus;

    @Prop({required: true, default: []})
    private plants!: PlantDTO[];

    @Emit('on-refresh-click')
    private onRefreshClick(): void {
        return;
    }

    private onSearchText(text: string): void {
        this.$store.commit('setSearchedText', text);
    }

    private onClickItem(plant: PlantDTO): void {
        if (plant.unitId) {
            this.$router.push({
                name: 'plant-details-page',
                params: {id: plant.unitId},
                query: {namePlant: plant.unitName, countryPlant: plant.country}
            })
            this.$store.commit('setSearchedText', '');
        }
    }
}
