
import Vue from 'vue';
import Component from 'vue-class-component';
import {PlantsService} from '@/services/plants-service';
import {PlantDTO} from '@/models/plant';
import {EventBus} from '@/utils';
import {Watch} from 'vue-property-decorator';
import PebbleAutoRefreshItem from '@/models/pebble/pebble-auto-refresh-item';

@Component({
    name: 'home-footer',
    watch: {
        localRange(value): void {
            this.$store.commit('setDateRange', value);
        }
    }
})
export default class HomeFooter extends Vue {
    private lastUpdateTime = new Date().toLocaleTimeString();
    private isLoading = false;
    private timer = -1;

    private autoRefreshItems: PebbleAutoRefreshItem[] = [
        {label: this.$t('footerText.refreshNow').toString(), value: 1, selected: false, primary: true},
        {label: this.$t('footerText.noAutomaticRefresh').toString(), value: -1, selected: false},
        {label: this.$t('footerText.oneMinuteRefresh').toString(), value: 60, selected: false},
        {label: this.$t('footerText.fiveMinuteRefresh').toString(), value: 300, selected: true},
        {label: this.$t('footerText.twentyMinuteRefresh').toString(), value: 1200, selected: false},
        {label: this.$t('footerText.oneHourRefresh').toString(), value: 3600, selected: false}
    ];

    private mounted(): void {
        this.generateAutoRefreshItems();
        this.autoRefreshItems.forEach(value => {
            if (value.selected) {
                this.onClickAutoRefreshItem(value.value);
            }
        });
    }

    private generateAutoRefreshItems(): void {
        this.autoRefreshItems = [];
        this.autoRefreshItems = [
            {label: this.$t('footerText.refreshNow').toString(), value: 1, selected: false, primary: true},
            {label: this.$t('footerText.noAutomaticRefresh').toString(), value: -1, selected: false},
            {label: this.$t('footerText.oneMinuteRefresh').toString(), value: 60, selected: false},
            {label: this.$t('footerText.fiveMinuteRefresh').toString(), value: 300, selected: true},
            {label: this.$t('footerText.twentyMinuteRefresh').toString(), value: 1200, selected: false},
            {label: this.$t('footerText.oneHourRefresh').toString(), value: 3600, selected: false}
        ];
    }


    @Watch('$i18n.locale')
    private onLocaleChange(): void {
        this.generateAutoRefreshItems();
    }

    private onClickAutoRefreshItem(value: number): void {
        switch (value) {
        case -1:
            return this.cancelTimer(value);
        case 1:
            return this.refreshNow();
        case 60:
        case 300:
        case 1200:
        case 3600:
            return this.setTimer(value);
        }
    }

    private cancelTimer(value: number): void {
        if (this.timer !== -1) {
            clearInterval(this.timer);
        }
        this.autoRefreshItems.map(element => {
            element.selected = element.value === value;
        });
    }

    private refreshNow(): void {
        this.lastUpdateTime = new Date().toLocaleTimeString();
        let selectedIndex = -1;
        this.autoRefreshItems.map((element, index) => {
            if (element.selected) {
                selectedIndex = index;
            }
        });
        this.autoRefreshItems[selectedIndex].selected = true;
        this.isLoading = true;
        this.reloadData();
    }

    private setTimer(value: number): void {
        const self = this;
        if (this.timer !== -1) {
            clearInterval(this.timer);
        }
        this.timer = setInterval(function () {
            self.lastUpdateTime = new Date().toLocaleTimeString();
            self.isLoading = true;
            self.reloadData();
        }, value * 1000);
        this.autoRefreshItems.map(element => {
            element.selected = element.value === value;
        });
    }

    private async reloadData(): Promise<void> {
        switch (this.$route.name) {
        case 'home-page':
            await this.reloadHome();
            break;
        case 'plant-details-page':
        default:
            //TODO: ADD OTHER RELOAD
            break;
        }
    }

    private async reloadHome(): Promise<void> {
        const self = this;
        try {
            const plantsService: PlantsService = new PlantsService();
            const plants: PlantDTO[] = (await plantsService.getPlants()).result.items;
            this.$store.commit('setPlants', plants);
        } catch (err) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingApplications');
            throw err;
        } finally {
            self.isLoading = false;
        }
    }
}
