import BaseService from '@/utils/base-service';
import {ApiResponse, ApiResponseList} from '@/models';
import {SignalrNotification} from '@/models/signalr-notification/signalr-notification';

export class NotificationService extends BaseService {
    constructor() {
        super('notifications', process.env.VUE_APP_META_DATA_FEED_API_BASE_URL + '/api/v1');
    }

    public async getNotifications(): Promise<ApiResponse<ApiResponseList<SignalrNotification>>> {
        return (await this.api.get(`${this.endpoint}`)).data;
    }

    public async markAsReadNotification(id: number): Promise<void> {
        return (await this.api.post(`${this.endpoint}/${id}/mark-as-read`)).data;
    }

    public async readAllNotifications(): Promise<void> {
        return (await this.api.post(`${this.endpoint}/clear-all`)).data;
    }
}
