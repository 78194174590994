
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import PebbleToolbarIcon from '@/components/pebble-components/pebble-icon-notification/pebble-toolbar-icon.vue';
import PebbleCard from '@/components/pebble-components/pebble-card/pebble-card.vue';
import PebblePopover from '@/components/pebble-components/pebble-popover/pebble-popover.vue';
import {PebbleAvatarInformation} from '@/models/pebble/pebble-avatar-information';
import PebbleIconData from '@/models/pebble/pebble-icon-data';

@Component({
    name: 'pebble-header-toolbar',
    components: {PebbleToolbarIcon, PebblePopover, PebbleCard}
})
export default class PebbleHeaderToolbar extends Vue {
    @Prop({
        default: (): PebbleAvatarInformation => {
            return {givenName: '', familyName: ''}
        }
    })
    private pebbleAvatarInformation!: PebbleAvatarInformation;

    @Prop({default: () => []})
    private toolbarButtons!: PebbleIconData[];

    private isVisibleUserInfo = false;

    private get userInitials(): string {
        return this.pebbleAvatarInformation.givenName.charAt(0) + this.pebbleAvatarInformation.familyName.charAt(0);
    }

    private onClickAvatar(): void {
        this.isVisibleUserInfo = !this.isVisibleUserInfo;
    }
}
