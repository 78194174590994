
import Vue from 'vue';
import {Donut} from '@/models/donut';
import {Prop} from 'vue-property-decorator';
import Component from 'vue-class-component';
import ChartDetailTile from '@/components/chart-detail-tile/chart-detail-tile.vue';

@Component({
    name: 'chart-detail-list',
    components: {ChartDetailTile}
})
export default class ChartDetailList extends Vue {

    @Prop({required: true})
    private data!: Donut[];

    @Prop({default: true})
    private disableFilter?: boolean;

    @Prop({required: true})
    private onClickClear!: Function;
}
