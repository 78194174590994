
import Component, {mixins} from 'vue-class-component';
import NotificationTile from '@/components/notification/components/notification-tile.vue';
import {Prop, Watch} from 'vue-property-decorator';
import {SignalrNotification} from '@/models/signalr-notification/signalr-notification';
import ComponentNotification from '@/mixins/component-notification';

@Component({
    name: 'notification-wrapper',
    components: {NotificationTile}
})
export default class NotificationWrapper extends mixins(ComponentNotification) {

    @Prop()
    private resetScrollList!: boolean;

    @Watch('resetScrollList')
    private resetScroll(value: boolean): void {
        if (value) {
            const pebbleAppsBody: HTMLCollectionOf<Element> = document.getElementsByClassName('notification-wrapper-body');
            if (pebbleAppsBody.length > 0) {
                pebbleAppsBody[0].scrollTo(0, 0);
                this.$forceUpdate();
            }
        }
    }

    private onClickNotification(notification: SignalrNotification): void {
        this.markAsReadSingleNotification(notification.id);
    }

    private onClickReadAllNotification(): void {
        this.readAllNotification();
    }

}
