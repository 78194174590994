export class LocalStorageHelper {
    public static setSelectedLanguage(val: any): void {
        localStorage.setItem(LocalStorageHelper.keys.selectedLanguage, JSON.stringify(val));
    }

    public static getSelectedLanguage(): string | null {
        const languageString = localStorage.getItem(LocalStorageHelper.keys.selectedLanguage);
        if (languageString !== null) {
            return JSON.parse(languageString);
        }
        return null;
    }

    private static keys = {
        selectedLanguage: 'uniper.outage.selectedLanguge',
    };
}
