import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import HomePage from '@/views/home/home-page.vue';
import PlantDetailsPage from '@/views/plant-details/plant-details-page.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/',
        name: 'home-page',
        component: HomePage,
    },
    {
        path: '/plant/:id',
        name: 'plant-details-page',
        component: PlantDetailsPage
    }
];

const router = new VueRouter({
    routes,
});

export default router;
