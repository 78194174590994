
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {Donut} from '@/models/donut';

@Component({
    name: 'custom-donut'
})
export default class CustomDonut extends Vue {
    @Prop({required: true})
    private data!: Donut[];

    @Prop({required: false, default: 'Title'})
    private title?: string;

    @Prop({required: false})
    private subtitle?: (value: Donut) => string;

    @Prop({required: false})
    private onSelectItem?: (value: Donut) => void;

    private selectedIndex = -1;

    private mounted(): void {
        this.data.map((value, index) => {
            if (value.link) {
                value.link.callback = (): void => this.onSelected(index);
            }
        })
    }

    private onSelected(index: number): void {
        let checked = false;
        this.data.map((value, i) => {
            if (this.selectedIndex === index) {
                value.active = false;
                checked = true;
            } else {
                value.active = index === i;
            }
        });
        if (checked)
            this.selectedIndex = -1;
        else
            this.selectedIndex = index;
        if (this.onSelectItem) {
            this.onSelectItem(this.data[this.selectedIndex]);
        }
    }
}
