
import Vue, {PropType} from 'vue';
import Component from 'vue-class-component';
import {Emit, Prop, PropSync} from 'vue-property-decorator';
import PebbleCard from '@/components/pebble-components/pebble-card/pebble-card.vue';
import {PebbleStatus} from '@/models/pebble/enums/pebble-status';
import {PebbleTableValue} from '@/models/pebble/pebble-table/pebble-table-value';
import PebbleTableV2 from '@/components/pebble-components/pebble-table/pebble-table-v2.vue';
import PebbleLoader from '@/components/pebble-components/pebble-loader/pebble-loader.vue';

@Component({
    name: 'pebble-table',
    components: {PebbleLoader, PebbleTableV2, PebbleCard},
})
export default class PebbleTable extends Vue {
    private get tableData(): PebbleTableValue {
        return {
            columns: this.columnsKey,
            data: this.dataSync,
            options: {
                headings: this.heading ?? [],
                sortable: this.sortableKey ?? [],
                texts: {
                    limit: this.$i18n.t('pebble.resultsPerPage').toString(),
                    filter: this.$i18n.t('pebble.searchResults').toString(),
                    filterPlaceholder: this.$i18n.t('pebble.search').toString(),
                    noResults: this.$i18n.t('pebble.noMatchingRecords').toString(),
                    page: this.$i18n.t('pebble.page').toString(),
                },
                perPage: this.perPage,
                perPageValues: this.perPageValues,
            }
        }
    }

    @PropSync('status', {type: Number, default: () => PebbleStatus.LOADING})
    private readonly statusSync!: PebbleStatus;

    @PropSync('data', {type: Array as PropType<object[]>, required: false, default: () => []})
    private readonly dataSync!: object[];

    @Prop({type: Array as PropType<string[]>, required: false, default: () => []})
    private readonly columnsKey!: string[];

    @Prop({type: Array as PropType<string[]>, required: false, default: () => []})
    private readonly sortableKey!: string[];

    @Prop({required: false})
    private heading?: object;

    @Prop({default: false, type: Boolean})
    private enableSearch!: boolean;

    @Prop({default: false, type: Boolean})
    private enablePagination!: boolean;

    @Prop({default: false, type: Boolean})
    private enableLimit!: boolean;

    @Prop({type: Array as PropType<number[]>, required: false, default: () => [10, 50, 100]})
    private perPageValues!: number[];

    @Prop({type: Number, required: false, default: 10})
    private perPage!: number;

    @Prop({default: () => false})
    private selected!: (item: object) => boolean;

    @Emit('on-refresh-click')
    private onRefreshClick(): void {
        return;
    }
}
