import {HubConnectionBuilder, LogLevel} from '@microsoft/signalr';
import AppSecurity from '@/utils/app-security';
import {PluginObject, VueConstructor} from 'vue';
import store from '@/store/index'
import {SignalrNotification} from '@/models/signalr-notification/signalr-notification';

export default {
    initialized: false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async install(app: VueConstructor, options: PluginObject<any>): Promise<void> {
        const appSecurity = new AppSecurity();
        const isSigned = (await appSecurity.getSignedIn() as boolean)
        if (!isSigned) {
            return;
        }
        if (this.initialized) {
            return;
        }
        const token = await appSecurity.getAcessToken();
        const notification = new HubConnectionBuilder()
            .withUrl(process.env.VUE_APP_EXTERNAL_APP_METADATA_SIGNALR_HUB, {
                accessTokenFactory: () => `${token}`,
                withCredentials: true,
            })
            .configureLogging(LogLevel.Information)
            .build();

        notification.onclose(function (error) {
            if (error) {
                console.error(`Connection closed with error: ${error}`);
            } else {
                console.log('Work');
            }
        });

        notification.on('Notification', (message: SignalrNotification) => {
            console.log(`Received message`, message);
            if (message.useCaseId !== +process.env.VUE_APP_USE_CASE_ID) return
            const notifications: SignalrNotification[] = store.getters.getNotifications;
            notifications.splice(0, 0, message)
            store.commit('setNotifications', notifications);
        });

        notification.start().then(() => {
            console.log('Hub connection started!');
            this.initialized = true;
        }).catch(err => {
            console.error('Error while establishing connection', err);
        });
    },
}

