
import Vue from 'vue';
import Component from 'vue-class-component';
import {Emit, Prop} from 'vue-property-decorator';
import PebbleDropdownData from '@/models/pebble/pebble-dropdown-data';

@Component({
    name: 'pebble-dropdown'
})
export default class PebbleDropdown extends Vue {
    @Prop({default: () => []})
    private values!: PebbleDropdownData[];

    @Prop({default: ''})
    private label!: string;

    @Prop()
    private defaultValue?: string;

    @Emit('on-value-changed')
    private onValueChanged(value: string): void {
        return;
    }

    private isSelected(value: string): boolean {
        if (!this.defaultValue) return false;
        return this.defaultValue.toLowerCase() === value.toLowerCase();
    }
}
