/* eslint-disable @typescript-eslint/explicit-function-return-type */

import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import AppSecurity from './app-security';
import {EventBus} from '.';

export default class APIConnection {
    private adamApi: AxiosInstance;
    private attempts!: number;
    private appSecurity = new AppSecurity();

    constructor(url: string) {
        this.adamApi = axios.create({
            baseURL: url,
        });
        this.adamApi.interceptors.request.use(async (config: any) => {
            const accessToken = await this.appSecurity.getAcessToken();
            config.headers.Authorization = `Bearer ${accessToken}`;
            return config;
        });

        this.adamApi.interceptors.response.use((success: any) => {
            if (success.config.method !== 'options') {
                this.attempts = 0;
            }
            return success;
        }, (error: any) => {
            console.log({error});
            if (error.response && error.response.status === 401) {
                this.appSecurity.signIn();
            } else if (error.response && error.response.status === 403) {
                window.location.href = `${window.location.origin}/authorization/access-forbidden.html`;
            } else if (error.response === undefined && error.message === 'Network Error') {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'networkError');
            } else {
                return Promise.reject(error);
            }
        });
    }

    public get(url: string, config?: AxiosRequestConfig) {
        return this.adamApi.get(url, config);
    }

    public post(url: string, data?: object, config?: AxiosRequestConfig) {
        return this.adamApi.post(url, data, config);
    }

    public put(url: string, data?: object, config?: AxiosRequestConfig) {
        return this.adamApi.put(url, data, config);
    }

    public delete(url: string, config?: AxiosRequestConfig) {
        return this.adamApi.delete(url, config);
    }
}
