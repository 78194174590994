
import './main.less';
import {Component, Watch} from 'vue-property-decorator';
import ChartCard from '@/components/chart-card/chart-card.vue';
import Loader from '@/components/loader/loader.vue';
import HomeFooter from '@/components/footer/home-footer.vue';
import PebbleHeader from '@/components/pebble-components/pebble-header/pebble-header.vue';
import PebbleDrawer from '@/components/pebble-components/pebble-drawer/pebble-drawer.vue';
import PebbleCard from '@/components/pebble-components/pebble-card/pebble-card.vue';
import UserCard from '@/components/user-card/user-card.vue';
import {mixins} from 'vue-class-component';
import ComponentSecurity from '@/mixins/component-security';
import PebbleApps from '@/components/pebble-components/pebble-apps/pebble-apps.vue';
import DrawerWrapper from '@/components/drawer/drawer-wrapper.vue';
import {PebbleAvatarInformation} from '@/models/pebble/pebble-avatar-information';
import PebbleIconData from '@/models/pebble/pebble-icon-data';
import ComponentNotification from '@/mixins/component-notification';

@Component({
    name: 'app',
    components: {
        DrawerWrapper,
        PebbleApps,
        UserCard,
        PebbleCard,
        PebbleDrawer,
        PebbleHeader,
        HomeFooter,
        Loader,
        ChartCard
    },
})
export default class App extends mixins(ComponentSecurity, ComponentNotification) {
    /* VARIABLES */
    private logo = require('@/assets/image/coode.svg');
    private appsDrawer = false;
    private notificationDrawer = false;
    private openSide = false;
    private toolbarButtons: PebbleIconData[] = [
        {
            iconName: 'alert',
            id: 0,
            notification: 0,
            onClick: this.onClickNotificationButton,
        }
    ];
    private avatarInformation: PebbleAvatarInformation = {
        familyName: '',
        givenName: '',
    };

    @Watch('$store.state.notifications')
    private onNotificationChange(): void {
        this.toolbarButtons[0].notification = this.$store.getters.getNotifications.length;
    }

    /* PRIMITIVE METHODS*/
    private mounted(): void {
        this.init();
    }

    /* METHODS */
    private async init(): Promise<void> {
        await this.initComponentSecurity();
        await this.getNotifications();
        this.toolbarButtons[0].notification = this.$store.getters.getNotifications.length;
        this.avatarInformation.givenName = this.userLogged.given_name;
        this.avatarInformation.familyName = this.userLogged.family_name;
    }

    private get sideIsEnabled(): boolean {
        // eslint-disable-next-line sonarjs/no-small-switch
        switch (this.$route.name) {
        case 'home-page':
            return true;
        default:
            return false;
        }
    }

    /* ON CLICK BUTTON */
    private onClickHomeButton(): void {
        if (this.$route.name !== 'home-page') {
            this.$store.commit('setSelectedCountries', []);
            this.$store.commit('setSelectedFleet', []);
            this.$router.push({name: 'home-page'});
        }
    }

    private onClickDrawerMenu(): void {
        this.appsDrawer = true;
    }

    private onClickNotificationButton(): void {
        this.notificationDrawer = true;
    }

    private onClickCloseDrawer(): void {
        this.appsDrawer = false;
        this.notificationDrawer = false;
    }
}
