
import Component from 'vue-class-component';
import Vue, {PropType} from 'vue';
import {Emit, Prop, Watch} from 'vue-property-decorator';
import SearchField from '@/components/search-field/search-field.vue';
import PebbleDropdown from '@/components/pebble-components/pebble-dropdown/pebble-dropdown.vue';
import {PebbleTableText} from '@/models/pebble/pebble-table/pebble-table-text';

@Component({
    name: 'pebble-table-caption',
    components: {PebbleDropdown, SearchField}
})
export default class PebbleTableCaption extends Vue {
    @Prop({default: false, type: Boolean})
    private enableSearch!: boolean;

    @Prop({default: false, type: Boolean})
    private enableLimit!: boolean;

    @Prop({type: Array as PropType<number[]>, required: false})
    private perPageValues!: number[];

    @Prop({type: Number, required: false})
    private perPage!: number;

    @Prop({type: Object as PropType<PebbleTableText>, required: false})
    private texts!: PebbleTableText;

    private searchText = ''

    @Emit('on-search')
    @Watch('searchText')
    private onSearch(value: string): void {
        return
    }

    @Emit('on-limit-change')
    private onLimitChange(value: string): void {
        return
    }

    private get classes(): string {
        return `pebble-table-caption ${this.enableLimit && !this.enableSearch ? 'pebble-table-caption-limit' : ''}`
    }
}
