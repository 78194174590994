
import Component from 'vue-class-component';
import Vue, {PropType} from 'vue';
import {Prop, PropSync} from 'vue-property-decorator';
import {PebbleTableText} from '@/models/pebble/pebble-table/pebble-table-text';
import {clone} from '@/utils/utils';

@Component({
    name: 'pebble-table-body'
})
export default class PebbleTableBody extends Vue {
    @PropSync('row', {type: Array as PropType<object[]>, required: false, default: () => []})
    private readonly rowSync!: object[];

    @Prop({type: Array as PropType<string[]>, required: false, default: () => []})
    private readonly columns!: string[];

    @Prop()
    private readonly texts?: PebbleTableText;

    @Prop()
    private perPage!: number;

    @Prop()
    private selectedPage!: number;

    private generateCellKey = (index: number, col: string): string => `${col}_${index}`;

    private get noMatchFound(): string {
        return this.texts?.noResults ?? 'No match found'
    }

    private get paginatedRows(): object[] {
        const tempList = clone(this.rowSync);
        return this.paginate(tempList, this.perPage, this.selectedPage)
    }

    private paginate(array: object[], page_size: number, page_number: number): object[] {
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }
}
