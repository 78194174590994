
import Vue from 'vue';
import Component from 'vue-class-component';
import {Emit, Prop, Watch} from 'vue-property-decorator';
import {Filter} from '@/models/pebble/pebble-filter/filter';
import {CustomObject} from '@/models/custom-object';
import {FilterOptions} from '@/models/pebble/pebble-filter/filter-options';

@Component({
    name: 'pebble-filter',
})
export default class PebbleFilter extends Vue {
    @Prop({default: () => []})
    private filters!: Filter[];

    @Emit('on-filter-change')
    private onFilterChange(data: CustomObject): void {
        return;
    }

    private filterOptions: FilterOptions = {
        filters: [],
    };

    @Watch('filters')
    private generateFilters(): void {
        this.filterOptions = {
            filters: [],
        };
        this.filters.forEach((value) => {
            this.filterOptions.filters.push(value);
        });
        this.$forceUpdate();
    }
}
