
import Vue from 'vue';
import {Emit, PropSync} from 'vue-property-decorator';
import {PebbleStatus} from '@/models/pebble/enums/pebble-status';
import Component from 'vue-class-component';
import PebbleCard from '@/components/pebble-components/pebble-card/pebble-card.vue';

@Component({
    name: 'pebble-loader',
    components: {PebbleCard}
})
export default class PebbleLoader extends Vue {
    @PropSync('status', {type: Number, default: () => PebbleStatus.LOADING})
    private readonly statusSync!: PebbleStatus;

    @Emit('on-refresh-click')
    private onRefreshClick(): void {
        return;
    }
}
