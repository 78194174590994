
import Vue from 'vue';
import Component from 'vue-class-component';
import PebbleAppsTile from '@/components/pebble-components/pebble-apps/components/pebble-apps-tile.vue';
import {Emit, Prop, Watch} from 'vue-property-decorator';
import PebbleApp from '@/models/pebble/pebble-app';
import PebbleAppsSection from '@/components/pebble-components/pebble-apps/components/pebble-apps-section.vue';
import PebbleAppsHeader from '@/components/pebble-components/pebble-apps/components/pebble-apps-header.vue';
import PebbleAppsConfiguration from '@/models/pebble/pebble-apps-configuration';

@Component({
    name: 'pebble-apps',
    components: {PebbleAppsHeader, PebbleAppsSection, PebbleAppsTile}
})
export default class PebbleApps extends Vue {
    @Prop()
    private resetScrollList!: boolean;

    @Prop({required: true})
    private apps!: PebbleApp[];

    @Prop({
        default: (): PebbleAppsConfiguration => {
            return {sections: [{name: '', label: 'Applications'}]};
        }
    })
    private configuration!: PebbleAppsConfiguration;

    @Emit('on-click-app')
    private onClickApp(app: PebbleApp): void {
        return;
    }

    @Watch('resetScrollList')
    private resetScroll(value: boolean): void {
        if (value) {
            const pebbleAppsBody: HTMLCollectionOf<Element> = document.getElementsByClassName('pebble-apps-body');
            if (pebbleAppsBody.length > 0) {
                pebbleAppsBody[0].scrollTo(0, 0);
                this.$forceUpdate();
            }
        }
    }

    private filterApps(name: string): PebbleApp[] {
        if (name.length === 0) return this.apps;
        return this.apps.filter((value) => value.type?.toLowerCase().includes(name.toLowerCase()));
    }
}
