
import Vue from 'vue';
import Component from 'vue-class-component';
import PebbleHeaderMenu from '@/components/pebble-components/pebble-header/components/pebble-header-menu.vue';
import PebbleHeaderTitleApp from '@/components/pebble-components/pebble-header/components/pebble-header-title-app.vue';
import PebbleHeaderToolbar from '@/components/pebble-components/pebble-header/components/pebble-header-toolbar.vue';
import {Emit, Prop} from 'vue-property-decorator';
import PebbleDrawer from '@/components/pebble-components/pebble-drawer/pebble-drawer.vue';
import {PebbleAvatarInformation} from '@/models/pebble/pebble-avatar-information';
import PebbleIconData from '@/models/pebble/pebble-icon-data';

@Component({
    name: 'pebble-header',
    components: {PebbleDrawer, PebbleHeaderToolbar, PebbleHeaderTitleApp, PebbleHeaderMenu},
})
export default class PebbleHeader extends Vue {
    @Prop()
    private title?: string;

    @Prop()
    private logo?: string;

    @Prop()
    private avatarInformation!: PebbleAvatarInformation;

    @Prop()
    private toolbarButtons!: PebbleIconData[];

    @Emit('on-click-drawer-menu')
    private onClickDrawerMenu(): void {
        return;
    }

    @Emit('on-click-home-button')
    private onClickHomeButton(): void {
        return;
    }

}
