
import Vue, {PropType} from 'vue';
import Component from 'vue-class-component';
import {Emit, Prop} from 'vue-property-decorator';
import {CustomObject} from '@/models/custom-object';
import PebbleTableHeaderSorter
    from '@/components/pebble-components/pebble-table/components/pebble-table-header-sorter.vue';
import {PebbleHeaderSort} from '@/models/pebble/pebble-header-sort';
import {PebbleHeaderSortStatus} from '@/models/pebble/enums/pebble-header-sort-status';

@Component({
    name: 'pebble-table-header',
    components: {PebbleTableHeaderSorter}
})
export default class PebbleTableHeader extends Vue {
    @Prop({type: Array as PropType<string[]>, required: false, default: () => []})
    private readonly columns!: string[];

    @Prop({type: Array as PropType<string[]>, required: false, default: () => []})
    private readonly sortable!: string[];

    @Prop({required: false})
    private heading?: CustomObject;

    @Emit('on-cell-sort-changed')
    private onCellSortChanged(value: PebbleHeaderSort[]): void {
        return;
    }

    private sortableKey: PebbleHeaderSort[] = this.columns.map((value => {
        return {
            columnKey: value,
            sortStatus: PebbleHeaderSortStatus.NOTHING,
        }
    }));

    private generateTitle(item: string): string {
        if (!this.heading) return item;
        if (!this.heading[item]) return item;
        return this.heading[item].toString();
    }

    private cellClasses(key: string): string {
        return `pebble-table-header-cell ${this.sortable.includes(key) ? 'pebble-table-header-cell-click' : ''}`
    }

    private onCellClick(key: string): void {
        if (!this.sortable.includes(key)) return;
        this.sortableKey.forEach((value: PebbleHeaderSort) => {
            if (value.columnKey === key) {
                switch (value.sortStatus) {
                case PebbleHeaderSortStatus.NOTHING:
                    value.sortStatus = PebbleHeaderSortStatus.ASCENDING;
                    break;
                case PebbleHeaderSortStatus.ASCENDING:
                    value.sortStatus = PebbleHeaderSortStatus.DESCENDING;
                    break;
                case PebbleHeaderSortStatus.DESCENDING:
                    value.sortStatus = PebbleHeaderSortStatus.NOTHING;
                    break;
                }
            }
            return value
        })
        this.onCellSortChanged(this.sortableKey)
    }

    private getSortableStatus(key: string): PebbleHeaderSortStatus {
        let status = PebbleHeaderSortStatus.NOTHING;
        this.sortableKey.forEach(value => {
            if (value.columnKey === key) {
                status = value.sortStatus;
            }
        })
        return status;
    }
}
