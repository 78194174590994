
import Vue from 'vue';
import Component from 'vue-class-component';
import {PropSync} from 'vue-property-decorator';
import {PebbleHeaderSortStatus} from '@/models/pebble/enums/pebble-header-sort-status';

@Component({
    name: 'pebble-table-header-sorter'
})
export default class PebbleTableHeaderSorter extends Vue {

    @PropSync('status', {type: Number, default: () => PebbleHeaderSortStatus.NOTHING})
    private readonly statusSync!: PebbleHeaderSortStatus;

    private get upClasses(): string {
        return this.statusSync === PebbleHeaderSortStatus.NOTHING || this.statusSync === PebbleHeaderSortStatus.ASCENDING ? 'icon-sort-unselected' : 'icon-sort-selected';
    }

    private get downClasses(): string {
        return this.statusSync === PebbleHeaderSortStatus.NOTHING || this.statusSync === PebbleHeaderSortStatus.DESCENDING ? 'icon-sort-unselected' : 'icon-sort-selected';
    }


}
