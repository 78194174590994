
import Vue from 'vue';
import Component from 'vue-class-component';
import {Emit, Prop, PropSync} from 'vue-property-decorator';
import PlantDetailTile from '@/components/plant-tile/plant-detail-tile.vue';
import {PlantDetail} from '@/models/plant-detail';
import {PebbleStatus} from '@/models/pebble/enums/pebble-status';
import PebbleCard from '@/components/pebble-components/pebble-card/pebble-card.vue';

@Component({
    name: 'plants-detail-list',
    components: {PebbleCard, PlantDetailTile},
})
export default class PlantsDetailList extends Vue {
    @PropSync('status', {type: Number, default: () => PebbleStatus.LOADING})
    private readonly statusSync!: PebbleStatus;

    @Prop({required: true})
    private plants!: PlantDetail[];

    @Emit('on-refresh-click')
    private onRefreshClick(): void {
        return;
    }
}
