
import Vue from 'vue';
import Component from 'vue-class-component';
import PebbleCard from '@/components/pebble-components/pebble-card/pebble-card.vue';
import PebblePopover from '@/components/pebble-components/pebble-popover/pebble-popover.vue';
import {Emit, Prop} from 'vue-property-decorator';
import {PebbleAvatarInformation} from '@/models/pebble/pebble-avatar-information';

@Component({
    name: 'pebble-drawer-head',
    components: {PebblePopover, PebbleCard},
})
export default class PebbleDrawerHead extends Vue {
    private isVisibleUserInfo = false;

    @Prop()
    private pebbleAvatarInformation?: PebbleAvatarInformation;

    @Emit('on-click-close-drawer')
    private closeDrawer(): void {
        return;
    }

    private get userInitials(): string {
        return this.pebbleAvatarInformation?.givenName?.charAt(0) ?? '' + this.pebbleAvatarInformation?.familyName?.charAt(0) ?? '';
    }

    private onClickAvatar(): void {
        this.isVisibleUserInfo = !this.isVisibleUserInfo;
    }
}
