
import Vue from 'vue';
import Component from 'vue-class-component';
import {PlantsService} from '@/services/plants-service';
import {EventBus} from '@/utils';
import {PlantDTO} from '@/models/plant';
import {FilterValue} from '@/models/pebble/pebble-filter/filter-value';
import {FleetService} from '@/services/fleet-service';
import {Fleet} from '@/models/fleet';
import PlantsComponent from '@/components/plants-list/plants-component.vue';
import PebbleCard from '@/components/pebble-components/pebble-card/pebble-card.vue';
import ChartCard from '@/components/chart-card/chart-card.vue';
import PageHeader from '@/components/page-header/page-header.vue';
import {Watch} from 'vue-property-decorator';
import {filterPlants} from '@/utils/utils';
import {PebbleStatus} from '@/models/pebble/enums/pebble-status';

@Component({
    name: 'home-page',
    components: {
        PageHeader,
        ChartCard,
        PebbleCard,
        PlantsComponent
    }
})
export default class HomePage extends Vue {
    private plantsService: PlantsService = new PlantsService();
    private fleetsService: FleetService = new FleetService();
    private dataStatus: PebbleStatus = PebbleStatus.LOADING;

    private mounted(): void {
        this.init();
    }

    @Watch('$i18n.locale')
    private async init(): Promise<void> {
        await this.loadFilters();
        await this.loadPlants();
    }

    /* API CALLS */
    private async loadFilters(): Promise<void> {
        try {
            const fleets = (await this.fleetsService.getFleets()).result.items;
            if (fleets) {
                const fleetFilter = this.generateFleetFilterValue(fleets);
                this.$store.commit('setFleetFilter', fleetFilter);
            }
        } catch (err) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingApplications');
            throw err;
        }
    }

    private async loadPlants(): Promise<void> {
        this.dataStatus = PebbleStatus.LOADING;
        try {
            const plants: PlantDTO[] = (await this.plantsService.getPlants()).result.items;
            const countryFilter = this.generateCountryFilterValue(plants);
            this.$store.commit('setPlants', plants);
            this.$store.commit('setFilteredPlants', plants);
            this.$store.commit('setCountryFilter', countryFilter);
            filterPlants(this.$store);
            this.dataStatus = PebbleStatus.LOADED
        } catch (err) {
            this.dataStatus = PebbleStatus.ERROR
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingApplications');
            throw err;
        }
    }

    private generateCountryFilterValue(plants: PlantDTO[]): FilterValue[] {
        const temp = plants.map(item => item.country).filter((item, i, ar) => ar.indexOf(item) === i);
        if (temp.length > 0) {
            return temp.map((value: string): FilterValue => {
                return {
                    displayName: this.$t(`country.${value.replace(/ /g, '').toLowerCase()}`).toString(),
                    value: value,
                }
            });
        }
        return [];
    }

    private generateFleetFilterValue(fleets: Fleet[]): FilterValue[] {
        return fleets.map((value: Fleet): FilterValue => {
            return {
                displayName: value.name,
                value: value.name,
            }
        });
    }

    @Watch('$store.state.searchedText')
    @Watch('$store.state.selectedCountries')
    @Watch('$store.state.selectedFleets')
    @Watch('$store.state.portfolioDonut')
    @Watch('$store.state.outageDonut')
    private onFiltersChanged(): void {
        filterPlants(this.$store);
    }
}
