
import Component from 'vue-class-component';
import PebbleTileCard from '@/components/pebble-components/pebble-card/pebble-tile-card.vue';
import {Prop, Vue} from 'vue-property-decorator';
import {PlantDTO} from '@/models/plant';
import {PebbleGridCols} from '@/models/pebble/pebble-grid-cols';
import moment from 'moment';
import {differenceTwoDates} from '@/utils/utils';
import {PlantType} from '@/models/enums/plant-type';

@Component({
    name: 'plant-tile',
    components: {PebbleTileCard},
})
export default class PlantTile extends Vue {
    @Prop({required: true})
    public plant!: PlantDTO;

    public checkPlanned(): boolean {
        return this.plant.type === PlantType.PLANNED;
    }

    get plantTypeColor(): string {
        const now = moment(new Date());
        if (now.isBetween(moment(this.plant.startDate), moment(this.plant.endDate))) {
            if (this.plant.capacity === 0) {
                return 'red';
            }
            return 'orange';
        }
        return 'green';
    }

    get generateHourText(): string {
        return differenceTwoDates(this.plant.startDate, this.plant.endDate);
    }

    get generatePowerDiffText(): string {
        return `${this.plant.capacity} / ${this.plant.totalCapacity} MW`;
    }

    get generateStartDateText(): string {
        return moment(this.plant.startDate).utc().format('DD/MM/YYYY (HH:mm)');
    }

    get generateEndDateText(): string {
        return moment(this.plant.endDate).utc().format('DD/MM/YYYY (HH:mm)');
    }

    get plantIsNotAvailable(): boolean {
        const now = moment(new Date());
        return now.isBetween(moment(this.plant.startDate), moment(this.plant.endDate));
    }

    get nameCountryCols(): PebbleGridCols {
        return {
            s: 12,
            m: 6,
            l: 6,
            xl: 3,
            xxl: 3,
        }
    }

    // eslint-disable-next-line sonarjs/no-identical-functions
    get dateCols(): PebbleGridCols {
        return {
            s: 12,
            m: 6,
            l: 6,
            xl: 3,
            xxl: 3,
        }
    }

    get timeCols(): PebbleGridCols {
        return {
            s: 12,
            m: 6,
            l: 6,
            xl: 4,
            xxl: 3,
        }
    }

    get powerCols(): PebbleGridCols {
        return {
            s: 12,
            m: 6,
            l: 6,
            xl: 2,
            xxl: 2,
        }
    }

    get badgeCols(): PebbleGridCols {
        return {
            s: 12,
            m: 12,
            l: 12,
            xl: 12,
            xxl: 1,
        }
    }

}
