
import Vue from 'vue';
import Component from 'vue-class-component';
import {Emit, Prop} from 'vue-property-decorator';
import PebbleTablePagination from '@/components/pebble-components/pebble-table/components/pebble-table-pagination.vue';

@Component({
    name: 'pebble-table-footer',
    components: {PebbleTablePagination}
})
export default class PebbleTableFooter extends Vue {
    @Prop()
    private rowsPerPage!: number;

    @Prop()
    private selectedPage!: number;

    @Prop()
    private totalRows!: number;

    @Emit('on-page-selected')
    private onPageSelected(value: number): void {
        return;
    }

    private get description(): string {
        const num = this.selectedPage * this.rowsPerPage;
        if (Math.ceil(this.totalRows / this.rowsPerPage) === 1) return this.$i18n.t('pebble.tableFooterDescription', [this.totalRows]).toString()
        return this.$i18n.t('pebble.tableFooterFullDescription', {
            from: (this.selectedPage * this.rowsPerPage) - this.rowsPerPage + 1,
            to: num > this.totalRows ? this.totalRows : num,
            total: this.totalRows
        }).toString();
    }
}
