import BaseService from '@/utils/base-service';
import {ApiResponse, ApiResponseList} from '@/models';
import {Fleet} from '@/models/fleet';

export class FleetService extends BaseService {
    constructor() {
        super('fleets', process.env.VUE_APP_API_BASE_URL + '/api/v1');
    }

    public async getFleets(): Promise<ApiResponse<ApiResponseList<Fleet>>> {
        return (await this.api.get(`${this.endpoint}`)).data;
    }
}
