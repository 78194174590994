import {PlantDTO} from '@/models/plant';
import moment from 'moment';
import {DonutValueType} from '@/models/enums/donut-value-type';
import {Store} from 'vuex';

export function timeout(callback: () => void, ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(() => {
        callback();
        resolve();
    }, ms))
}

export function filterPlants(store: Store<any>): void {
    let tempList: PlantDTO[] = store.getters.getPlants;
    if (store.getters.getSearchedText !== '') {
        tempList = tempList.filter(el =>
            el.unitName?.toLowerCase().includes(store.getters.getSearchedText.toLowerCase()) ||
            el.plantName?.toLowerCase().includes(store.getters.getSearchedText.toLowerCase())
        );
    }
    if (store.getters.getSelectedCountries.length > 0) {
        tempList = tempList.filter(el => store.getters.getSelectedCountries.includes(el.country));
    }
    if (store.getters.getSelectedFleet.length > 0) {
        tempList = tempList.filter(el => store.getters.getSelectedFleet.includes(el.fleetName));
    }
    const now = moment(new Date());
    if (store.getters.getPortfolioDonut) {
        tempList = tempList.filter(el => {
            switch (store.getters.getPortfolioDonut.donutValueType) {
            case DonutValueType.UNAVAILABLE:
                return now.isBetween(moment(el.startDate), moment(el.endDate)) && el.capacity === 0;
            case DonutValueType.REDUCED_AVAILABLE:
                return now.isBetween(moment(el.startDate), moment(el.endDate)) && el.capacity > 0;
            case DonutValueType.AVAILABLE:
                return !now.isBetween(moment(el.startDate), moment(el.endDate));
            }
        });
    }
    if (store.getters.getOutageDonut) {
        tempList = tempList.filter(el => {
            if (now.isBetween(moment(el.startDate), moment(el.endDate)))
                return el.type === store.getters.getOutageDonut.donutValueType
            return false
        });
    }
    store.commit('setFilteredPlants', tempList);
}

export function differenceTwoDates(startDate?: Date, endDate?: Date, options?: { disableHours: boolean; disableMinutes: boolean }): string {
    if (startDate && endDate) {
        const leftDate = new Date(endDate);
        const rightDate = new Date(startDate);
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        let diffInMilliSeconds = Math.abs(rightDate - leftDate) / 1000;

        // calculate days
        const days = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;

        // calculate hours
        const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= hours * 3600;

        // calculate minutes
        const minutes = Math.floor(diffInMilliSeconds / 60) % 60;

        let difference = '';
        if (days > 0) {
            difference += `${days} days `;
        }
        if (hours > 0 && (!options?.disableHours ?? true)) {
            difference += `${hours} hours `;
        }
        if (minutes > 0 && (!options?.disableMinutes ?? true)) {
            difference += `${minutes} minutes`;
        }
        return difference;
    }
    return '';
}

export function debounce(fn: Function, ms = 300): Function {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: any[]): void {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
}

export function clone(items: Array<any>): Array<any> {
    return items.map(item => Array.isArray(item) ? clone(item) : item)
}

export function lowerCaseAndWithoutSpace(value: string): string {
    return value.replaceAll(/\s/g, '').toLowerCase();
}
