
import Vue from 'vue';
import Component from 'vue-class-component';
import PebbleCard from '@/components/pebble-components/pebble-card/pebble-card.vue';
import PebbleDrawerHead from '@/components/pebble-components/pebble-drawer/components/pebble-drawer-head.vue';
import {Emit, Prop} from 'vue-property-decorator';
import {PebbleAvatarInformation} from '@/models/pebble/pebble-avatar-information';
import {PebblePosition} from '@/models/pebble/enums/pebble-position';

@Component({
    name: 'pebble-drawer',
    components: {PebbleDrawerHead, PebbleCard},
})
export default class PebbleDrawer extends Vue {
    @Prop({required: true})
    private visible!: boolean;

    @Prop()
    private avatarInformation!: PebbleAvatarInformation;

    @Prop({default: () => PebblePosition.LEFT})
    private position!: PebblePosition;

    @Emit('on-click-close-drawer')
    private closeDrawer(): void {
        return;
    }

    private get generateClass(): string {
        if (this.visible) {
            return `pebble-drawer pebble-drawer-active`;
        } else {
            return `pebble-drawer`;
        }
    }

    private get generateClassBackDrop(): string {
        if (this.visible) {
            return `pebble-drawer-backdrop`;
        } else {
            return `pebble-drawer-backdrop pebble-drawer-backdrop-inactive`;
        }
    }

    private get generateClassDrawer(): string {
        if (this.visible) {
            return `pebble-drawer-container pebble-drawer-container-${this.position} pebble-drawer-container-active`;
        } else {
            return `pebble-drawer-container pebble-drawer-container-${this.position}`;
        }
    }
}
