import Component from 'vue-class-component';
import Vue from 'vue';
import AppSecurity from '@/utils/app-security';
import {UniperUser} from '@/models/uniper-user';

@Component
export default class ComponentSecurity extends Vue {
    public appSecurity = new AppSecurity();
    public isSigned = false;
    public userLogged: UniperUser = {
        sub: '',
        family_name: '',
        permission: '',
        given_name: '',
    };

    public async initComponentSecurity(): Promise<void> {
        this.isSigned = await this.sessionIsSigned();
        if (this.isSigned) {
            await this.appSecurity.setPermissions();
        }
        await this.getUser();
    }

    private async sessionIsSigned(): Promise<boolean> {
        return (await this.appSecurity.getSignedIn() as boolean)
    }


    private async getUser(): Promise<void> {
        const profile: any = await this.appSecurity.getProfile();
        if (profile) {
            this.userLogged = {
                family_name: profile.family_name,
                given_name: profile.given_name,
                sub: profile.sub,
                permission: profile.permission,
            };
        }
    }
}
