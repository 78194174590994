
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component({
    name: 'pebble-apps-section',
})
export default class PebbleAppsSection extends Vue {
    @Prop({required: true})
    private title!: string;
}
