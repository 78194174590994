
import Vue from 'vue';
import Component from 'vue-class-component';
import {Emit, Prop, Watch} from 'vue-property-decorator';

@Component({
    name: 'pebble-table-pagination'
})
export default class PebbleTablePagination extends Vue {
    @Prop()
    private rowsPerPage!: number;

    @Prop()
    private selectedPage!: number;

    @Prop()
    private totalRows!: number;

    private pagesInSection = 6;
    private startPage = 1;
    private endPage = 6;
    private totalPages = 0;

    @Emit('on-page-selected')
    private onPageSelected(value: number): void {
        return;
    }

    @Watch('rowsPerPage')
    private setPagination(): void {
        this.onPageSelected(1);
        this.startPage = 1;
        this.totalPages = Math.ceil(this.totalRows / this.rowsPerPage);
        this.setEndPage();
    }

    private mounted(): void {
        this.setPagination();
    }

    private setEndPage(): void {
        if (Math.ceil(this.totalRows / this.rowsPerPage) < this.pagesInSection) {
            this.endPage = this.totalPages;
        } else {
            this.endPage = this.pagesInSection;
        }
    }

    private prevPage(): void {
        let temp = this.selectedPage;
        if (temp === 1) return;
        temp = temp - 1;
        if (temp % this.pagesInSection === 0) {
            this.startPage -= this.pagesInSection;
            this.endPage -= this.pagesInSection;
            if (this.endPage < this.pagesInSection) {
                this.setEndPage()
            }
        }
        this.onPageSelected(temp);
    }

    private nextPage(): void {
        let temp = this.selectedPage;
        if (temp === this.totalPages) return;
        if (temp % this.pagesInSection === 0) {
            this.startPage += this.pagesInSection;
            this.endPage += this.pagesInSection;
            if (this.endPage > this.totalPages) {
                this.endPage = this.totalPages;
            }
        }
        if (temp === this.totalRows + 1) return;
        temp = temp + 1;
        this.onPageSelected(temp);
    }

    private pageIsVisible(page: number): boolean {
        return page >= this.startPage && page <= this.endPage;
    }

    private spanClasses(page: number): string {
        return `pebble-table-pagination-page ${page === this.selectedPage ? 'pebble-table-pagination-page-selected' : ''} ${page === this.endPage ? '' : 'pebble-table-pagination-page-border'}`
    }
}
