import { render, staticRenderFns } from "./chart-detail-list.vue?vue&type=template&id=2201f6ed&scoped=true&"
import script from "./chart-detail-list.vue?vue&type=script&lang=ts&"
export * from "./chart-detail-list.vue?vue&type=script&lang=ts&"
import style0 from "./chart-detail-list.vue?vue&type=style&index=0&id=2201f6ed&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2201f6ed",
  null
  
)

export default component.exports