import Vue from 'vue';

export const EventBus = new Vue({
    data(): any {
        return {
            GLOBAL: {
                SHOW_SNACKBAR: 'global.show-snackbar',
            },
        };
    },
});
