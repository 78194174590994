import Vue from 'vue';
import App from './App.vue';
import VueI18n from 'vue-i18n';
import router from './router';
import store from './store';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faCaretDown,
    faCaretRight,
    faCaretUp,
    faCopy,
    faGripLines,
    faSort,
    IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import 'adam.ui-core/dist/umd';
import 'adam.ui-core/utils/scaling.css';
import './registerServiceWorker';
import {VNode} from 'vue/types/umd';
import AppSecurity from '@/utils/app-security';
import PebbleUI from '@enerlytics/pebble-ui/dist/pebble-ui';
import SignalrConnection from '@/utils/signalr-connection';
import i18n from '@/utils/i18n';

Vue.config.productionTip = false;

Vue.component('font-awesome-icon', FontAwesomeIcon);

library.add(
    faCopy as IconDefinition,
    faCaretRight as IconDefinition,
    faCaretUp as IconDefinition,
    faCaretDown as IconDefinition,
    faSort as IconDefinition,
    faGripLines as IconDefinition,
);

Vue.use(VueI18n);
Vue.use(PebbleUI);
AppSecurity.initialize();
Vue.use(SignalrConnection);

new Vue({
    i18n,
    router,
    store,
    render: (h): VNode => h(App),
}).$mount('#app');
