import BaseService from '@/utils/base-service';
import {ApiResponse, ApiResponseList, Application} from '@/models';

/**
 * @name AppService
 * @description This class is used to query the identity server for user related data,
 * which is not related to authentication.
 */
export class AppService extends BaseService {
    constructor() {
        super('use-cases', process.env.VUE_APP_META_DATA_FEED_API_BASE_URL + '/api/v1');
    }

    public async getApps(): Promise<ApiResponse<ApiResponseList<Application>>> {
        return (await this.api.get(`${this.endpoint}`)).data;
    }
}
