
import Component from 'vue-class-component';
import Vue from 'vue';
import {Emit, Prop} from 'vue-property-decorator';

@Component({
    name: 'simple-button'
})
export default class SimpleButton extends Vue {
    @Prop({required: true})
    private text!: string;

    @Emit('on-click-button')
    private onClickButton(): void {
        return;
    }
}
