
import Vue from 'vue';
import Component from 'vue-class-component';
import {Emit, Prop} from 'vue-property-decorator';
import PebbleApp from '@/models/pebble/pebble-app';

@Component({
    name: 'pebble-apps-tile',
})
export default class PebbleAppsTile extends Vue {
    @Prop({required: true})
    private app!: PebbleApp;

    @Emit('on-click-app')
    private onClickApp(app: PebbleApp): void {
        return;
    }

    private get generateClass(): string {
        /*if (this.app.isEnabled) {
            return 'pebble-apps-tile';
        }
        return 'pebble-apps-tile pebble-apps-tile-disabled';*/
        return 'pebble-apps-tile';
    }

    private get getLogo(): string {
        try {
            require(`adam.ui-core/assets/icons/${this.app.logo}.svg`);
            return this.app.logo;
        } catch (e) {
            return 'app-generic';
        }
    }
}
