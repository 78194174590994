
import Vue from 'vue';
import Component from 'vue-class-component';
import PebbleCard from '@/components/pebble-components/pebble-card/pebble-card.vue';
import AppSecurity from '@/utils/app-security';
import {UniperUser} from '@/models/uniper-user';
import {EventBus} from '@/utils';
import SimpleButton from '@/components/buttons/simple-button.vue';
import {Prop} from 'vue-property-decorator';
import PebbleDropdown from '@/components/pebble-components/pebble-dropdown/pebble-dropdown.vue';
import PebbleDropdownData from '@/models/pebble/pebble-dropdown-data';
import {LocalStorageHelper} from '@/utils/local-storage-helper';
import {LANGUAGES} from '@/utils/consts';

@Component({
    name: 'user-card',
    components: {PebbleDropdown, SimpleButton, PebbleCard},
})
export default class UserCard extends Vue {
    private appSecurity = new AppSecurity();
    private vueVersion?: string = process.env.VUE_APP_VERSION;
    private apiUrl?: string = process.env.VUE_APP_API_BASE_URL;
    private swaggerURL?: string = process.env.VUE_APP_API_SWAGGER_URL;

    private locales: PebbleDropdownData[] = [];
    private selectedLocale = '';

    /* LATE VARIABLES */
    @Prop({required: true})
    private user!: UniperUser;

    private mounted(): void {
        this.loadLocale();
        this.generateLocales();
    }

    /* METHODS */
    private async copyToken(): Promise<void> {
        const token = await this.appSecurity.getAcessToken();
        await navigator.clipboard.writeText(`Bearer ${token}`);
        EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'copiedToClipboard');
    }

    private logout(): void {
        this.appSecurity.signOut();
    }

    private onLocaleChanged(value: string): void {
        this.selectedLocale = value;
        LocalStorageHelper.setSelectedLanguage(value);
        this.$i18n.locale = value;
        this.generateLocales();
    }

    private loadLocale(): void {
        const languages = Object.keys(LANGUAGES);
        const locale = LocalStorageHelper.getSelectedLanguage() ?? languages[0];
        if (locale) {
            this.onLocaleChanged(locale);
        }
    }

    private generateLocales(): void {
        this.locales = [
            {
                value: 'en-GB',
                label: this.$t('locales.en-GB').toString()
            }, {
                value: 'de-DE',
                label: this.$t('locales.de-DE').toString()
            },
        ];
    }
}
