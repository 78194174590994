
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component({
    name: 'notification-tile-header',
})
export default class NotificationTileHeader extends Vue {
    @Prop({default: ''})
    private subtitle!: string;
}
