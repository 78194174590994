
import Vue from 'vue';
import Component from 'vue-class-component';
import {Emit, Prop} from 'vue-property-decorator';
import PebbleDivider from '@/components/pebble-components/pebble-divider/pebble-divider.vue';

@Component({
    name: 'pebble-header-title-app',
    components: {PebbleDivider},
})
export default class PebbleHeaderTitleApp extends Vue {
    @Prop()
    private title?: string;

    @Prop()
    private logo?: string;

    @Emit('on-click-home-button')
    private onClickHomeButton(): void {
        return;
    }
}
