
import Vue from 'vue';
import Component from 'vue-class-component';
import {Emit, Prop} from 'vue-property-decorator';

@Component({
    name: 'pebble-toolbar-icon'
})
export default class PebbleToolbarIcon extends Vue {
    @Prop({required: true})
    private iconName!: string;

    @Prop({default: 0})
    private notification?: number;

    @Emit('on-click-button')
    private onClickButton(): void {
        return;
    }

    private get text(): string {
        if (!this.notification) return '';
        return this.notification >= 100 ? '...' : this.notification.toString();
    }
}
